html, body {
  margin: 0;
  overflow-y: hidden;

  font-family: sans-serif;
}

h4 {
  margin-top: 1rem;
}

.btn-wrapper {
  display: flex;
  gap: .2rem;
}

.input-group {
  margin-top: .5rem;
}

.main {
  height: 100vh;
  display: flex;
}

.editor-container {
  height: 100%;
  flex: 6;
}

#editor-container {
  height: 100%;
}

.out-log-container {
  border-radius: .25rem;
  border: 1px solid lightgray;
  padding: 10px;
  flex: 1 1;

  overflow-y: scroll;
}

#out-log {
  overflow-wrap: break-word;
}

#out-log .log-time {
  color: gray;
}

.control-surface {
  margin: 10px;

  flex: 4;
  display: flex;
  flex-direction: column;
}

#out-log .last-change {
  font-style: italic;
  font-size: 0.7rem;
}

#out-log p {
  display: inline-block;
  margin: 0;
}